<template>
    <form @submit.prevent="submit">
        <BaseFormComponent id="new_cart_name" label="Ange kundvagnens namn">
            <BaseInput
                type="text"
                id="new_cart_name"
                v-model="name"
                maxlength="32"
            />
        </BaseFormComponent>

        <BaseFormButtons />
    </form>
</template>

<script>
import { getErrors } from '@/core/http'
import cartsHttp from '../../home/cartsHttp'
import BaseFormComponent from '@/core/components/BaseFormComponent'
import BaseInput from '@/core/components/BaseInput'
import BaseFormButtons from '@/core/components/BaseFormButtons'

export default {
    name: 'CreateCartForm',
    emits: [
        'created',
    ],
    components: {
        BaseFormComponent,
        BaseInput,
        BaseFormButtons,
    },
    data() {
        return {
            name: '',
        };
    },
    methods: {
        submit() {
            this.createCart()
                .then(cart => {
                    this.$emit('created', cart);
                })
                .catch(err => {
                    const errors = getErrors(err);

                    errors.forEach(text => {
                        this.$flashMessage.show({
                            type: 'error',
                            text,
                        });
                    });
                })
            ;
        },
        async createCart() {
            return await cartsHttp.post({
                name: this.name,
            });
        },
    },
};
</script>
