<template>
	<main>
		<h1>Ny kundvagn</h1>

        <CreateCartForm @created="created" />
	</main>
</template>

<script>
import cartsLocalStorage from '../../home/cartsLocalStorage'
import CreateCartForm from '@/modules/cart/components/CreateCartForm'

export default {
    name: 'CreateCart',
    components: {
        CreateCartForm,
    },
    methods: {
        created(cart) {
            cartsLocalStorage.save(cart.id);

            this.$router.push(`/carts/${cart.id}`);
        },
    },
};
</script>
